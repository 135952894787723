import * as React from "react"
import '../neat-basics/index.scss'
import "../pages/theming.scss"
import "../styles/styles.scss"
import "../modules/related-publications.scss"

import {graphql} from "gatsby";
import {BaseArticle} from "../modules/base-article";
import {Article, MarkdownArticle, Publication} from "../types";
import Main from "../modules/main";


const ResearchArticle = (articleData: { data: { markdownRemark: MarkdownArticle; allMarkdownRemark: { nodes: Article[]; }; publications: {nodes: Publication[]}; thougthArticles: {nodes: Article[]} };  }) => {
  const article = articleData.data.markdownRemark
  const olderArticles = articleData.data.allMarkdownRemark.nodes.filter(a => a.frontmatter.date < article.frontmatter.date)
  const relatedPublicationsTextValues = article.frontmatter.relatedResearch
  const relatedThoughtsTextValues = article.frontmatter.relatedThoughts
  const relatedPublications = articleData.data.publications ? articleData.data.publications.nodes.filter(p => relatedPublicationsTextValues?.includes(p.frontmatter.text)) : []
  const relatedThougthArticles = articleData.data.thougthArticles ? articleData.data.thougthArticles.nodes.filter(t => relatedThoughtsTextValues?.includes(t.frontmatter.title)) : []

  return <Main title={article.frontmatter.title}>
    <BaseArticle
        content={article.html}
        title={article.frontmatter.title}
        image={article.frontmatter.image}
        teaserSectionTitle="Related Thoughts"
        teaseredArticles={relatedThougthArticles}
        moreTeaseredArticlesSectionTitle="Further research projects"
        moreTeaseredArticles={olderArticles.slice(0, 2)}
        sideContent={<div className="related-publications"><div className="related-publications__title">Related Publications:</div><ul>{relatedPublications.map(p => <li><a target="_blank" rel="noopener noreferrer" href={p.frontmatter.url}>{p.frontmatter.text}</a></li>)}</ul></div>}
        date={article.frontmatter.date}
        activePage="research"
    />
  </Main>
}

export default ResearchArticle

export const pageQuery = graphql`
  query ResearchArticleByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date
        title
        image {
           childImageSharp {
             gatsbyImageData(width: 1500)
           }
        }
        relatedResearch
        relatedThoughts
      }
    }
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "research_article"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        html
        excerpt(format: PLAIN, pruneLength: 5000)
        fields {
          slug
        }
        frontmatter {
          date
          title
        }
      }
    }
    publications: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "publication"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        html
        frontmatter {
          date
          text
          url
          type
        }
      }
    }
    thougthArticles: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "thought_article"}}}, sort: { fields: [frontmatter___date], order: DESC}) {
      nodes {
        id
        html
        excerpt(format: PLAIN, pruneLength: 5000)
        fields {
          slug
        }
        frontmatter {
          date
          title
        }
      }
    }
  }
`
